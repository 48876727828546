<template>
  <div class="filmcontest-container" v-if="showAbs">
    <router-link to="/home" class="logoBox">
      <el-image :src="logo"></el-image>
    </router-link>

    <el-image :src="img1" id="fitst" lazy></el-image>
    <div class="img2-po">
      <el-image :src="img2" lazy style="display:block;" />
      <a href="https://www.osp.osaka-info.jp/e-pass/jp/" target="_blank">
        <el-image :src="img3" lazy class="img2-ra w100" style="display:block"></el-image>
      </a>
      <a href="https://www.tripellet.com/" target="_blank">
        <el-image :src="img4" lazy class="img2-ra2 w100" style="display:block"></el-image>
      </a>
    </div>
    <div class="img5Box">
      <el-image :src="img5" lazy style="display:block;"></el-image>
      <div class="text">
        <span>賞品の使い方詳しくKLOOK公式サイト：</span><br><br>
        <a href="https://www.klook.com/ja/klook-gift-card/" target="_blank">https://www.klook.com/ja/klook-gift-card/</a>
      </div>
    </div>
    <el-image :src="img6" lazy style="display:block;"></el-image>
    <div class="img5-po">
      <el-image :src="img8" lazy style="display:block;"></el-image>
      <el-image :src="img7" lazy class="img5-ra" style="display:block;"></el-image>
      <a href="https://www.facebook.com/permalink.php?story_fbid=128071179468240&id=102781358663889" target="_blank">
        <el-image :src="img9" lazy class="img5-ra2" style="display:block;"></el-image>
      </a>
      <a href="https://forms.gle/63C35ywq5CyS7QsWA" target="_blank" @click="step3ClickChange">
        <el-image :src="step3Click==false?img10:img19" lazy class="img5-ra3 "></el-image>
      </a>
      <a href="https://forms.gle/63C35ywq5CyS7QsWA" target="_blank" @click="step3ClickChange">
        <el-image :src="step3Click==false?img19:img10" lazy class="img5-ra3 step3"></el-image>
      </a>
    </div>
    <el-image :src="img11"></el-image>
    <div class="img12Box">
      <div class="videoBox">
        <iframe src="https://www.youtube.com/embed/c8-J1ug8HcQ" frameborder="0"></iframe>
      </div>
      <el-image :src="img12" style="display:block;"></el-image>
    </div>
    <div class="img8-po">
      <el-image :src="img13" lazy style="display:block;"></el-image>
      <a href="https://www.osp.osaka-info.jp/e-pass/jp/" target="_blank">
        <el-image :src="bottomLogo" lazy class="img8-ra"></el-image>
      </a>
      <a href="https://www.tripellet.com/" target="_blank">
        <el-image :src="img4" lazy class="img8-ra2" style="display:block;"></el-image>
      </a>
      <!-- <el-image
        :src="img16"
        lazy
        class="img8-ra3"
       
      ></el-image> -->
    </div>
    <el-image :src="img17" lazy></el-image>
    <div class="img18Box">
      <a class="email" href="https://www.tripellet.com">https://www.tripellet.com</a>
      <a class="url" href="mailto:tripellet@gmail.com">tripellet@gmail.com</a>
      <el-image :src="img18" class="w100" lazy></el-image>
      <div class="BottomlogoBox">
        <a href="https://www.osp.osaka-info.jp/e-pass/jp/" target="_blank">
          <el-image :src="bottomLogo" class="w100 logo1" lazy></el-image>
        </a>
        <div style="margin:2rem"></div>
        <a href="https://affiliate.klook.com/redirect?aid=18588&label1=website&label2=campaign-LP&label3=&k_site=https%3A%2F%2Fwww.klook.com%2Fja%2Factivity%2F53087%2F%3Faid%3D18588" target="_blank">
          <el-image :src="bottomLogo1" class="w100 logo2 " lazy></el-image>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      logo: require('@/assets/images/filmcontest/logo.png'),
      img1: require('@/assets/images/filmcontest/contest-1.png'),
      img2: require('@/assets/images/filmcontest/contest-2.png'),
      img3: require('@/assets/images/filmcontest/contest-3.png'),
      img4: require('@/assets/images/filmcontest/contest-4.png'),
      img5: require('@/assets/images/filmcontest/contest-5.png'),
      img6: require('@/assets/images/filmcontest/contest-6.png'),
      img7: require('@/assets/images/filmcontest/contest-7.png'),
      img8: require('@/assets/images/filmcontest/contest-8.png'),
      img9: require('@/assets/images/filmcontest/contest-9.png'),
      img10: require('@/assets/images/filmcontest/contest-10.png'),
      img11: require('@/assets/images/filmcontest/contest-11.png'),
      img12: require('@/assets/images/filmcontest/contest-12.png'),
      img13: require('@/assets/images/filmcontest/contest-13.png'),
      img14: require('@/assets/images/filmcontest/contest-14.png'),
      img15: require('@/assets/images/filmcontest/contest-15.png'),
      img16: require('@/assets/images/filmcontest/contest-16.png'),
      img17: require('@/assets/images/filmcontest/contest-17.png'),
      img18: require('@/assets/images/filmcontest/contest-18.png'),
      img19: require('@/assets/images/filmcontest/contest-19.png'),
      bottomLogo: require('@/assets/images/filmcontest/bottom-logo1.png'),
      bottomLogo1: require('@/assets/images/filmcontest/bottom-logo2.png'),
      step3Click: true,
      showAbs: false,
    }
  },
  mounted() {
    this.openFullScreen2()
    setTimeout(() => {
      this.showAbs = true
    }, 888)
  },
  methods: {
    step3ClickChange() {
      if (this.step3Click == false) return (this.step3Click = true)
      if (this.step3Click == true) return (this.step3Click = false)
    },
    openFullScreen2() {
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(255, 255, 255, 1)',
      })

      if (document.getElementById('fitst') != null) {
        setTimeout(() => {
          loading.close()
        }, 500)
      } else {
        setTimeout(() => {
          this.openFullScreen2()
        }, 50)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.filmcontest-container {
  width: 750px;
  margin: 0 auto;

  padding: 0;
}
.logoBox {
  padding: 18px 0px;
  display: block;
}
.img2-po {
  position: relative;
  .img2-ra {
    position: absolute;
    left: 5%;
    top: 58%;
    width: 40%;
    z-index: 20;
  }
  .img2-ra2 {
    position: absolute;
    right: 5%;
    top: 58%;
    width: 40%;
    z-index: 20;
  }
}
.img5-po {
  position: relative;
  top: -0.7px;
  .img5-ra {
    position: absolute;
    top: 0;
    left: 20%;
    width: 60%;
    z-index: 20;
    animation: slide-top 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite both;
  }
  .img5-ra2 {
    position: absolute;
    top: 42%;
    left: 28%;
    width: 40%;
    z-index: 20;
  }
  .img5-ra3 {
    position: absolute;
    top: 66.5%;
    left: 28%;
    width: 40%;
    z-index: 20;
  }
}
.img8-po {
  position: relative;
  .img8-ra {
    position: absolute;
    top: 33%;
    left: 24.5%;
    width: 18%;
    z-index: 20;
  }
  .img8-ra2 {
    position: absolute;
    top: 45%;
    left: 51%;
    width: 26%;
    z-index: 20;
  }
  .img8-ra3 {
    position: absolute;
    top: 26%;
    right: 7%;
    width: 26%;
    z-index: 20;
  }
}
.img18Box {
  position: relative;
  text-align: center;
  font-family: 'GenJyuuGothic';
  font-size: 24px;
  top: -2px;
  a {
    font-weight: bold;
  }
  a:hover,
  a:link,
  a:visited {
    color: black;
  }

  .url {
    position: absolute;
    width: 100%;
    top: 29%;
    z-index: 1000;
  }
  .email {
    position: absolute;
    width: 100%;
    top: 47.5%;
    z-index: 1000;
  }
  .BottomlogoBox {
    position: absolute;
    bottom: 10%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    .logo1 {
      flex: 1;
    }
    .logo2 {
      flex: 1;
    }
  }
}
.img5Box {
  position: relative;
  text-align: center;
  font-family: 'GenJyuuGothic';
  font-size: 17px;
  a:hover,
  a:link,
  a:visited {
    color: black;
  }
  .text {
    width: 100%;
    position: absolute;
    bottom: 5%;
    z-index: 1000;
  }
}
.img12Box {
  position: relative;
  width: 100%;
  .videoBox {
    position: absolute;
    z-index: 1000;
    width: 100%;
    height: 600px;
    text-align: center;
    top: 25.5%;
    iframe {
      width: 85%;
      height: 55%;
    }
  }
}
.step3 {
  opacity: 1;
  z-index: 9999;
}
.step3:hover {
  opacity: 0;
}
@keyframes slide-top {
  0% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes shadow-drop-2-center {
  0% {
    transform: translateZ(0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
  100% {
    transform: translateZ(50px);
    box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.35);
  }
}
</style>
